<template>
  <v-card tile width="100%">
    <v-card-text class="pa-0">
      <v-data-table
        multi-sort
        :headers="headers"
        :items="menu"
        :loading="loading"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" @click="openEditDialog(item)" small icon>
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn class="mr-2" small icon @click="openConfirmationForDeleting(item.id)">
            <v-icon
              small
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog max-width="320" v-model="edit.show">
      <validation-observer ref="editMenuItemObserver" v-slot="{ passes }">
        <v-form @submit.prevent="passes(submit)">
          <v-card>
            <v-card-text>
              <validation-provider name="menu item title" rules="required" v-slot="{ errors }">
                <v-text-field v-model="edit.item['menu_item']" placeholder="Edit menu item title"
                            :error-messages="errors"
                            counter
                            label="Menu item title"></v-text-field>
              </validation-provider>
              <validation-provider name="menu item slug" rules="required" v-slot="{ errors }">
                <v-text-field v-model="edit.item['slug']" placeholder="Edit menu item slug"
                            :error-messages="errors"
                            counter
                            label="Menu item slug"></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn type="button" @click="closeEditDialog">Cancel</v-btn>
              <v-btn type="submit" color="primary">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <starboxx-confirmation
      :action="{data: id, message: 'Do you really want to delete this menu item?', name: 'delete-menu-item'}"/>
  </v-card>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      loading: null,
      edit: {
        show: false,
        item: {}
      },
      menu: [],
      id: null,
      headers: [
        { text: 'Menu item', value: 'menu_item', sortable: true },
        { text: 'Slug', value: 'slug', sortable: true },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  beforeMount () {
    this.getMenu()
  },
  mounted () {
    this.loading = 'danger'
    this.$root.$on('delete-menu-item', (id) => {
      this.deleteMenuItem(id)
    })
  },
  methods: {
    getMenu () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/api/menu`)
        .then(response => {
          this.menu = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    openConfirmationForDeleting (id) {
      this.id = id
      this.$store.dispatch('setConfirmation', true)
    },
    deleteMenuItem (id) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/api/menu/${id}`)
        .then(() => {
          this.getMenu()
        })
    },
    openEditDialog (item) {
      this.edit.item = item
      this.edit.show = true
    },
    closeEditDialog () {
      this.edit.show = false
      this.edit.item = {}
    },
    submit () {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/api/menu/${this.edit.item.id}`, this.edit.item)
        .then(() => {
          this.closeEditDialog()
          this.$refs.editMenuItemObserver.reset()
          this.getMenu()
        })
    }
  }
}
</script>

<style scoped>

</style>
