var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","width":"100%"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.menu,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openConfirmationForDeleting(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.edit.show),callback:function ($$v) {_vm.$set(_vm.edit, "show", $$v)},expression:"edit.show"}},[_c('validation-observer',{ref:"editMenuItemObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"menu item title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Edit menu item title","error-messages":errors,"counter":"","label":"Menu item title"},model:{value:(_vm.edit.item['menu_item']),callback:function ($$v) {_vm.$set(_vm.edit.item, 'menu_item', $$v)},expression:"edit.item['menu_item']"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"menu item slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Edit menu item slug","error-messages":errors,"counter":"","label":"Menu item slug"},model:{value:(_vm.edit.item['slug']),callback:function ($$v) {_vm.$set(_vm.edit.item, 'slug', $$v)},expression:"edit.item['slug']"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.closeEditDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Update")])],1)],1)],1)]}}])})],1),_c('starboxx-confirmation',{attrs:{"action":{data: _vm.id, message: 'Do you really want to delete this menu item?', name: 'delete-menu-item'}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }